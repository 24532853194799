import "regenerator-runtime/runtime";
import Vue from "vue/dist/vue.esm";
import Vuelidate from "vuelidate";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import isBetween from "dayjs/plugin/isBetween";
import dayjsBusinessDays from "dayjs-business-days";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(dayjsBusinessDays);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("fr");
dayjs.tz.setDefault("Europe/Paris");

import env from "../env";

document.addEventListener("DOMContentLoaded", () => {
    // https://dev.to/surjithctly/solved-2020-pure-vanilla-javascript-smooth-scroll-to-element-on-a-click-id-2cek
    document.querySelectorAll('a[href^="#"]').forEach(trigger => {
        trigger.onclick = function(e) {
            e.preventDefault();
            let hash = this.getAttribute("href");
            let target = document.querySelector(hash);
            let headerOffset = 10;
            let elementPosition = target.offsetTop;
            let offsetPosition = elementPosition - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        };
    });
});

window.onscroll = () => {
    if (!window.matchMedia("(max-width: 35rem)").matches) {
        const header = document.querySelector("header");
        if (window.scrollY < 800) {
            header.style.backgroundPositionY = `calc(40% + ${window.scrollY * 0.5}px)`;
        }
    }
};

function lignees() {
    // Create SVG container
    let svgContainer = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svgContainer.style.position = "absolute";
    svgContainer.style.left = "0";
    svgContainer.style.top = "0";
    svgContainer.style.border = "thin solid dashed";
    svgContainer.style.zIndex = "1";
    document.body.insertAdjacentElement("afterbegin", svgContainer);

    renderSVG(svgContainer);
    window.onresize = () => renderSVG(svgContainer);

    function renderSVG(svgContainer) {
        let SVGLines = [];
        let parents = document.querySelectorAll("[data-name]");
        parents.forEach(parent => {
            let parentCoords = parent.getBoundingClientRect();
            // Checking children
            if (parent.dataset.children) {
                let children = parent.dataset.children.split(",").map(n => n.trim());
                children.forEach(childName => {
                    let child = document.querySelector(`[data-name="${childName}"]`);
                    if (!child) return;

                    let { x, y, width, height } = child.getBoundingClientRect();
                    SVGLines.push({
                        x1: parentCoords.x + window.scrollX + parentCoords.width / 2,
                        y1: parentCoords.y + window.scrollY + parentCoords.height,
                        x2: x + window.scrollX + width / 2,
                        y2: y + window.scrollY
                    });
                });
            }
        });

        // Adapt SVG container size to maximum coordinates
        let maxWidth = Math.ceil(
            Math.max(
                ...SVGLines.reduce((acc, c) => {
                    acc.push(c.x1, c.x2);
                    return acc;
                }, [])
            )
        );
        let maxHeight = Math.ceil(
            Math.max(
                ...SVGLines.reduce((acc, c) => {
                    acc.push(c.y1, c.y2);
                    return acc;
                }, [])
            )
        );

        svgContainer.setAttribute("width", maxWidth);
        svgContainer.setAttribute("height", maxHeight);
        svgContainer.style.width = `${maxWidth}px`;
        svgContainer.style.height = `${maxHeight}px`;

        // Process SVG lines
        let renderedLines = SVGLines.map(({ x1, y1, x2, y2 }) => `<line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" stroke="gray" stroke-dasharray="4" />`).join("\n");
        // Insert SVG <line /> elements
        svgContainer.innerHTML = renderedLines;
    }
}

import { required, email, helpers } from "vuelidate/lib/validators";
const frenchPhone = helpers.regex("phone", /^0[0-9][.\s-]?([0-9]{2}[.\s-]?){4}$/);

function home() {
    Vue.use(Vuelidate);

    new Vue({
        el: "main",
        data() {
            return {
                dates: null,
                currentDate: dayjs(),
                practitioners: [],
                formData: {
                    practitioner: "",
                    dateAppointment: "",
                    customerLastname: "",
                    customerFirstname: "",
                    customerPhone: "",
                    customerEmail: ""
                },
                formSubmitted: false,
                formSentSuccess: false,
                formSentError: false,
                formSentInfo: {}
            };
        },
        validations: {
            formData: {
                practitioner: { required },
                dateAppointment: { required },
                customerLastname: { required },
                customerFirstname: { required },
                customerPhone: { required, frenchPhone },
                customerEmail: { email }
            }
        },
        methods: {
            nextDates() {
                this.currentDate = this.currentDate.businessDaysAdd(3);
            },
            prevDates() {
                if (this.currentDate.businessDaysSubtract(3).isAfter(dayjs())) {
                    this.currentDate = this.currentDate.businessDaysSubtract(3);
                } else {
                    this.currentDate = dayjs();
                }
            },
            setTime(dateObj, hours, minutes) {
                return dateObj
                    .set("hour", hours)
                    .set("minute", minutes)
                    .set("second", 0)
                    .set("millisecond", 0);
            },
            toLocaleTime(timeString) {
                let [h, i] = timeString.split(":");
                let now = new Date();
                let d = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), h, i, 0));
                return `${d
                    .getHours()
                    .toString()
                    .padStart(2, "0")}:${d
                    .getMinutes()
                    .toString()
                    .padStart(2, "0")}`;
            },
            chooseDate(dateObj, hours, minutes) {
                let dateAppointment = this.setTime(dateObj, hours, minutes);

                // Déselection si la date est déjà choisie
                /* if (this.formData.dateAppointment && this.formData.dateAppointment.isSame(dateAppointment)) {
                    this.formData.dateAppointment = "";
                    return;
                } */

                this.formData.dateAppointment = dateAppointment;
                this.$v.formData.dateAppointment.$touch();
            },
            isLessThan24h(dateObj, hours, minutes) {
                return dateObj
                    .set("hour", hours)
                    .set("minute", minutes)
                    .isBefore(dayjs().businessDaysAdd(1));
            },
            isSlotValid(slotStart, slotEnd, chosenPractitioner) {
                // ===============
                // Vérification du praticien
                // ===============

                if (!chosenPractitioner) return false;

                let practitioner = this.practitioners.find(p => p.id === chosenPractitioner);

                if (!practitioner) return false;

                // ===============
                // Vérification de la disponibilité du créneau slotStart-slotEnd
                // ===============

                let slotIsFree = true;
                let unavailabilities = practitioner.unavailabilities;
                for (let unavailability of unavailabilities) {
                    if (!unavailability.daysOfWeek) {
                        // Indisponibilités liées aux RDVs
                        let start = dayjs(unavailability.dateStart);
                        let end = dayjs(unavailability.dateEnd);

                        if (slotStart.isBetween(start, end, null, "[)") || slotEnd.isBetween(start, end, null, "[)")) {
                            slotIsFree = false;
                            break;
                        }
                    } else {
                        // Indisponibilités récurrentes définies par le praticien
                        for (let dayOfWeek of unavailability.daysOfWeek) {
                            let [sh, sm] = this.toLocaleTime(unavailability.timeStart).split(":");
                            let [eh, em] = this.toLocaleTime(unavailability.timeEnd).split(":");

                            let dateStart = slotStart
                                .clone()
                                .set("day", dayOfWeek)
                                .set("hour", sh)
                                .set("minute", sm)
                                .set("second", 0)
                                .set("milliseconds", 0);

                            let dateEnd = slotStart
                                .clone()
                                .set("day", dayOfWeek)
                                .set("hour", eh)
                                .set("minute", em)
                                .set("second", 0)
                                .set("milliseconds", 0);

                            if (slotStart.isBetween(dateStart, dateEnd, null, "[)") || slotEnd.isBetween(dateStart, dateEnd, null, "[)")) {
                                slotIsFree = false;
                                break;
                            }
                        }
                    }
                }

                return slotIsFree;
            },
            async submitForm() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }

                this.formSubmitted = true;

                try {
                    // Formulaire validé !
                    const newAppointment = {
                        ...(this.formData.customerEmail && { customerEmail: this.formData.customerEmail }),
                        customerFirstname: this.formData.customerFirstname,
                        customerLastname: this.formData.customerLastname,
                        customerPhone: this.formData.customerPhone,
                        dateAppointment: this.formData.dateAppointment.toISOString(),
                        practitioner: this.formData.practitioner
                    };

                    const response = await fetch(`${env.FIREBASE_CLOUD_FUNCTIONS_ENDPOINT}/setAppointment`, {
                        method: "POST",
                        body: JSON.stringify(newAppointment),
                        headers: { "Content-Type": "application/json" }
                    });

                    if (response.status >= 400) {
                        const errorText = await response.text();
                        throw new Error(errorText);
                    }

                    const info = await response.json();

                    this.formSentInfo = info;
                    this.formSentError = false;
                    this.formSentSuccess = true;
                } catch (e) {
                    this.formSubmitted = false;
                    this.formSentError = e.message;
                    this.formSentSuccess = false;
                }
            },
            async resetForm() {
                this.currentDate = dayjs();
                this.formData = {
                    practitioner: "",
                    dateAppointment: "",
                    customerLastname: "",
                    customerFirstname: "",
                    customerPhone: "",
                    customerEmail: ""
                };
                this.$v.$reset();
                this.formSubmitted = false;
                this.formSentSuccess = false;
                this.formSentError = false;
                this.formSentInfo = {};
                const practitioners = await fetch(`${env.FIREBASE_CLOUD_FUNCTIONS_ENDPOINT}/getPractitioners`).then(res => res.json());
                this.practitioners = practitioners;
            }
        },
        computed: {
            isToday() {
                return this.currentDate.isSame(dayjs(), "day");
            },
            formDataPractitioner() {
                return this.formData.practitioner;
            },
            customerInfosNotEmpty() {
                return this.formData.customerLastname !== "" || this.formData.customerFirstname !== "" || this.formData.customerPhone !== "" || this.formData.customerEmail;
            },
            practitionerName() {
                const practitioner = this.practitioners.find(p => p.id === this.formData.practitioner);
                if (practitioner) return `${practitioner.firstname} ${practitioner.lastname}`;

                return "";
            }
        },
        watch: {
            formDataPractitioner() {
                // Reset si on change de praticien
                this.currentDate = dayjs();
                this.formData.dateAppointment = "";
            }
        },
        async mounted() {
            this.resetForm();
        }
    });
}

// Naive controller (@todo : change for a better system)
window.onload = () => {
    let page = document.querySelector('body[class^="page-"]');
    if (page !== null) {
        switch (page.className) {
            case "page-lignees":
                lignees();
                break;
            case "page-home":
                home();
                break;
        }
    }
};
